import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { EmailConfirmationInput } from "./pages/EmailConfirmationInput";
import { EmailConfirmationComplete } from "./pages/EmailConfirmationComplete";
import { ResetPasswordInput } from "./pages/ResetPasswordInput";
import { ResetPasswordComplete } from "./pages/ResetPasswordComplete";

const router = createBrowserRouter([
  {
    path: "/registration/:signature",
    element: <EmailConfirmationInput />,
  },
  {
    path: "/registration/complete",
    element: <EmailConfirmationComplete />,
  },
  {
    path: "/accounts/reset-password/:signature",
    element: <ResetPasswordInput />,
  },
  {
    path: "/accounts/reset-password/complete",
    element: <ResetPasswordComplete />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
