import React from "react";
import ReactDOM from "react-dom/client";
// import "../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css";
import "./styles/common.css";
import "./styles/variables.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
