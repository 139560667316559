import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { RouteProps } from "react-router-dom";
import "./ResetPasswordComplete.css";

const ResetPasswordComplete: FC<RouteProps> = () => {
    return (
        <>
            <Helmet>
                <title>パスワード再設定完了 | Aitem</title>
                <meta name='description' content='『パスワード再設定完了』ページです。' />
            </Helmet>

            <div className='ResetPasswordComplete l-wraper'>
                <div className='l-container'>
                    <div className='c-logo'>
                        <img alt='Aitem' width='100' height='34' src={`${process.env.PUBLIC_URL}/images/aitem_logo.png`} />
                    </div>
                    <div className='l-body'>
                        <h2 className='c-heading'>パスワード再設定が完了</h2>
                        <p className='ResetPasswordComplete__Text'>
                            <span>アプリでログインをお試しください。</span>
                            <span>レッスンのご参加、お待ちしております！</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export { ResetPasswordComplete };
